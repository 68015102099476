.labs-management table tr td button div span {
    font-size: 1.1em !important;
}

@media screen and (max-width: 1919px) {

    /* Labs Table Stuff */

    .labs-management .id-column {
        padding-left: 20px !important;
    }
    .labs-management .column-header,
    .labs-management .column {
        font-size: 12px !important;
    }
    .labs-management .username-column {
        width: 130px;
    }
    .labs-management .timer-count-column {
        width: 140px;
    }
    .labs-management .version-column {
        width: 45px;
    }
    .labs-management .lab-type-column {
        width: 140px;
    }
    .labs-management .ip-column {
        width: 150px;
    }
    .labs-management .status-actions-drop-down-menu {
        margin: 0 60px 0 -20px;
    }
    .labs-management .actions-column {
        width: 90px;
    }

    .general-table-container .table-styles {
        height: 65vh;
    }
    .labs-management table th,
    .labs-management table td {
        padding: 0 5px !important;
    }

    /* --- */

    /* ====== student-seminar-labs-list ====== */
    .student-seminar-labs-list .control-buttons-block .status-actions-drop-down {
        right: 95px !important;
    }
    .seminar-labs-list.labs-management .username-column {
        width: 130px;
    }

    .group-management .group-actions-column {
        width: 560px;
    }
}
@media screen and (max-width: 1679px) {
    .lab-details .list.right-list .list-item.first-item {
        height: 16px;
        overflow-y: auto;
    }

    /* User Table Stuff */

    .user-management .username-column {
        width: 160px;
        min-width: 160px;
        max-width: 160px;
    }
    .user-management .email-column {
        width: 160px;
        min-width: 160px;
        max-width: 160px;
    }
    .user-management .role-column {
        width: 125px;
        min-width: 125px;
    }
    .user-management .last-login-column {
        width: 150px;
        min-width: 150px;
    }
    .user-management .actions-column {
        width: 200px;
        min-width: 200px;
    }
    .user-management .maintenance-column {
        width: 80px;
        min-width: 80px;
    }

    /* --- */

    /* ======= SEMINARS LIST ====== */
    .seminar-list .column,
    .seminar-list .header-column {
        padding: 0 5px !important;
    }
    .seminar-list .id-column {
        padding-left: 10px !important;
    }

    .group-management .group-actions-column {
        width: 560px;
    }
}
@media screen and (max-width: 1599px) {
    .lab-details .list.left-list {
        width: 80px;
    }
    .lab-details .list.right-list {
        width: 160px;
    }
    .lab-details .list.right-list .list-item.first-item {
        height: 32px;
    }
    .group-management .group-actions-column {
        width: 560px;
    }
    .labs-details .subscription-name-column,
    .labs-management .subscription-name-column {
        width: 100px;
    }

    .horizontal-stuff .sidebar {
        width: 145px;
    }
    .sidebar .list-item > div > div {
        padding: 16px 8px !important;
    }
    .sidebar .list-item a {
        font-size: 14px;
        margin-right: 0;
    }
    .seminar-users-list,
    .seminar-labs-list,
    .student-seminar-labs-list,
    .seminar-list,
    .lab-templates-management,
    .labs-management,
    .group-management,
    .user-management,
    .reports-management,
    .user-history {
        width: calc(100% - 145px);
    }
}
@media screen and (max-width: 1439px) {
    .lab-details .list.left-list {
        width: 65px;
    }
    .lab-details .list .list-item.private-ip {
        height: 35px;
    }
    .user-history .filter-history-block .filter-block {
        margin-right: 10px !important;
    }
    .labs-management .id-column {
        padding-left: 10px !important;
    }
    .labs-management table th,
    .labs-management table td {
        padding: 0 2px !important;
    }

    .labs-management .last-action-column {
        display: none;
    }

    .lab-details .instance-block {
        width: 100%;
    }
}